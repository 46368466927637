import React, {useContext, useMemo} from 'react'
import {EmptyState, ReactTable} from '../../components/blocks'
import {LuBanknote, LuListOrdered} from 'react-icons/lu'
import {useQuery} from '@tanstack/react-query'
import {Apis} from '../../utils/apis'
import axios from 'axios'
import {SiteContext} from '../../context/SiteContext'
import {format, parseISO} from 'date-fns'
import {formatNumber, getStatusText} from '../../utils/general'

const HisRedeemTickets = () => {

  const {fondo, config} = useContext(SiteContext);

  const {data, isFetched} = useQuery({
    queryKey: ['getTicketsReedem', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/operation-ticket/report/by-fondo?fondoId=' + fondo?.id)
      .then((res) => res.data),
    enabled: !!fondo?.id
  })

  const columns = useMemo(
    () => [
      {
        header: 'Estado',
        id: 'status',
        accessorFn: row => row.status ? getStatusText(row.status) : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Numero de ticket',
        accessorKey: 'validNumber'
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount !== null ? formatNumber(row.amount, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Emitido en',
        id: 'machine',
        accessorFn: row => row.machine ? row.machine : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha de creación',
        id: 'emittedAt',
        accessorFn: row => `${row.emittedAt ? format(parseISO(row.emittedAt), "dd/MM HH:mm") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Canjeado en',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fecha de canje',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM HH:mm") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    , [config]
  )

  return (
    <div className='flex flex-col flex-1 gap-4'>
      {isFetched &&
        <>
          <div className='flex flex-row items-center gap-4'>
            <div className='flex flex-row items-center flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
              <div className='flex flex-col flex-1'>
                <span className='text-lg font-bold'>{data?.quantity ? formatNumber(data.quantity, null) : '--'}</span>
                <span className='text-xs font-light'>Tickets canjeados</span>
              </div>
              <div className='p-3 bg-neutral-900 rounded-xl'>
                <LuListOrdered size={20} />
              </div>
            </div>
            <div className='flex flex-row flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
              <div className='flex flex-col flex-1'>
                <span className='text-lg font-bold'>{data?.totalAmount ? formatNumber(data.totalAmount, config.symbol) : '--'}</span>
                <span className='text-xs font-light'>Total</span>
              </div>
              <div className='p-3 bg-neutral-900 rounded-xl'>
                <LuBanknote size={20} />
              </div>
            </div>
          </div>
          {data?.data && data.data.length > 0 ? 
            <ReactTable columns={columns} data={data.data} />
            :
            <EmptyState />
          }
        </>
      }
    </div>

  )
}

export default HisRedeemTickets
