import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Button, Dialog, DialogContent, DialogOverlay, FormControl} from "../../atoms";
import {ComboMachine, ReactTable} from "../index";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {Apis} from "../../../utils/apis";
import {toast} from "../../../hooks/useToast";
import {formatNumber} from "../../../utils/general";
import {SiteContext} from "../../../context/SiteContext";
import {format, parseISO} from "date-fns";

const CreateTicket = ({ticketCode}) => {
    const {config} = useContext(SiteContext);
    const [selectedMachine, setSelectedMachine] = useState({confId: null, machineName: ''});
    const [open, setOpen] = useState(false);

    const createManualTicket = useMutation({
      mutationKey: ['create_manual_ticket'],
      mutationFn: (data) => axios.post(Apis.TICKET + '/ticket-by-caja/create', data),
      onSuccess: () => {
        toast({
          title: "Operación realizada!",
          variant: 'success',
          duration: 2000
        })
        handleClear()
      },
      onError: (error) => {
        let errorMessage = 'Ha ocurrido un error en la solicitud';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        handleClear()
        toast({
          title: errorMessage,
          variant: 'danger',
          duration: 2000
        })
      }
    });

    const handleClear = () => {
      setTableStacker([]);
      setOpen(false);
      setSelectedMachine({confId: null, machineName: ''});
    };

    const [tableStacker, setTableStacker] = useState([]);

    const stackerTransactionMutation = useMutation({
      mutationKey: ['stacker_transaction'],
      mutationFn: (data) => axios.get(Apis.CAJA + `/operation-ticket/by-conf?confId=${data.confId}`, data),
      onSuccess: (response) => {
        setTableStacker(response.data);
        toast({
          title: "Operación realizada!",
          variant: 'success',
          duration: 2000
        });
      },
      onError: (error) => {
        let errorMessage = 'Ha ocurrido un error en la solicitud';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        toast({
          title: errorMessage,
          variant: 'danger',
          duration: 2000
        })
      }
    });

    useEffect(() => {
      setTableStacker([]);
    }, [ticketCode]);

    const columns = useMemo(
      () => [
        {
          header: 'N° Ticket',
          accessorKey: 'validNumber',
        },
        {
          header: 'Creación',
          id: 'createdAt',
          accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--'}`,
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          },
        },
        {
          header: 'Monto',
          id: 'amount',
          accessorFn: row => `${row.amount ? formatNumber(row.amount, config.symbol) : '--'}`,
          meta: {
            headerClassName: 'text-right',
            columnClassName: 'text-right',
          }
        },
        {
          header: '',
          accessorKey: 'actions',
          enableGlobalFilter: false,
          enableSorting: false,
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          },
          cell: info => (
            <div className='flex flex-row justify-center gap-2'>
              {
                !info.row.original.created ?
                  <Button variant='outline' size='sm'
                          onClick={() => createManualTicket.mutate({
                            ...info.row.original,
                            vn: ticketCode.substring(2),
                            validationType: 0,
                            amount: info.row.original.amount * 100,
                            validationSystemId: ticketCode.substring(0, 2)
                          })}>Crear</Button>
                  :
                  <span>Existente</span>
              }
            </div>
          ),
        }
      ]
      , [config, createManualTicket, ticketCode]
    );

    useEffect(() => {
      if (selectedMachine.confId) {
        stackerTransactionMutation.mutate({
          confId: selectedMachine.confId
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMachine]);

    return (
      <>
        <FormControl>
          <Button size={'sm'} style={{width: '100%'}}
                  onClick={() => setOpen(true)}
                  variant={'outline'}
          >
            Registrar ticket
          </Button>
        </FormControl>

        <Dialog open={open} onOpenChange={setOpen}>
          <DialogOverlay/>
          <DialogContent className='flex flex-col gap-4' style={{width: '1500px !important'}}>
            <div>
              <div>
                <FormControl>
                  <label>Seleccione máquina</label>
                  <ComboMachine selectedMachine={selectedMachine} setSelectedMachine={setSelectedMachine}/>
                </FormControl>

                {
                  selectedMachine.confId &&
                  <FormControl style={{paddingTop: '5rem'}}>
                    {
                      tableStacker.length > 0 &&
                      <ReactTable columns={columns} data={tableStacker} filter={false}/>
                    }
                  </FormControl>
                }

              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
;

export default CreateTicket;
