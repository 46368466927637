import React, {useState} from 'react'
import styles from './historial.module.css';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../components/atoms'
import {LuBanknote, LuTicket} from 'react-icons/lu';
import HisRedeemTickets from './hisRedeemTickets';
import HisHandpay from './hisHandpay';

const HistorialOutput = () => {

  const [activeOutputTab, setActiveOutputTab] = useState('tickets');

  return (
    <Tabs defaultValue={activeOutputTab} value={activeOutputTab} onValueChange={setActiveOutputTab} >

      <TabsContent value="tickets">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>TICKETS CANJEADOS</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>
      <TabsContent value="pagos">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>PAGOS MANUALES</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>

      <div className='flex flex-col self-stretch flex-1 p-4 bg-neutral-800 rounded-2xl'>
        <TabsList>
          <TabsTrigger value="tickets" asChild>
            <div className={styles.customTabsTrigger}>
              <LuTicket size={24} />
              <span>Tickets</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="pagos" asChild>
            <div className={styles.customTabsTrigger}>
              <LuBanknote size={24} />
              <span>Pagos</span>
            </div>
          </TabsTrigger>
        </TabsList>

        <TabsContent value="tickets" className='mt-6'>
          <HisRedeemTickets />

        </TabsContent>

        <TabsContent value="pagos" className='mt-6'>
          <HisHandpay />
        </TabsContent>

      </div>
    </Tabs>
  )
}

export default HistorialOutput