import React from 'react'
import {FaSpinner} from 'react-icons/fa'

const BlockLoading = () => {
  return (
    <div className='flex flex-col items-center justify-center w-full gap-4 py-6 select-none animate-pulse'>
      <FaSpinner className='animate-spin' size={24} />
      <span>Cargando...</span>
    </div>
  )
}

export default BlockLoading