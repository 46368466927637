import React, {useState} from 'react'
import styles from './historial.module.css';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../components/atoms'
import {LuCreditCard, LuTicket} from 'react-icons/lu'
import HisSaleTicket from './hisSaleTicket'
import HisCardpay from './hisCardpay'

const HistorialInput = () => {

  const [activeTab, setActiveTab] = useState('tickets');

  return (
    <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className='flex flex-col flex-1'>
      <TabsContent value="tickets">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>TICKETS VENDIDOS</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>
      <TabsContent value="tarjeta">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>VENTA POR TARJETAS</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>
      <div className='flex flex-col self-stretch flex-1 p-4 bg-neutral-800 rounded-2xl'>
        <TabsList>
          <TabsTrigger value="tickets" asChild>
            <div className={styles.customTabsTrigger}>
              <LuTicket size={24} />
              <span>Tickets</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="tarjeta" asChild>
            <div className={styles.customTabsTrigger}>
              <LuCreditCard size={24} />
              <span>Tarjetas</span>
            </div>

          </TabsTrigger>
        </TabsList>
        <TabsContent value="tickets" className='mt-6'>
          <HisSaleTicket />
        </TabsContent>
        <TabsContent value="tarjeta" className='mt-6'>
          <HisCardpay />
        </TabsContent>
        </div>
    </Tabs>
  )
}

export default HistorialInput