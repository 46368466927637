import React, {useContext, useMemo, useRef, useState} from 'react'
import {Button, FormControl, Input} from '../../components/atoms'
import {LuAlertCircle, LuBanknote, LuListOrdered} from 'react-icons/lu';
import {useQuery} from '@tanstack/react-query';
import {Apis} from '../../utils/apis';
import axios from 'axios';
import {toast} from '../../hooks/useToast';
import {format, parseISO} from 'date-fns';
import {ReactTable} from '../../components/blocks';
import {formatNumber, getStatusText} from '../../utils/general';
import {SiteContext} from '../../context/SiteContext';

const TicketReview = () => {

  const {config} = useContext(SiteContext);
  const [ticketCode, setTicketCode] = useState('');
  const [ticketHistory, setTicketHistory] = useState([]);
  const inputRef = useRef(null);

  const totalAmount = useMemo(() => {
    let total = 0;
    ticketHistory.forEach(ticket => {
      if (ticket.validationType !== 34) total += ticket.amount;
    });
    return total;
  }, [ticketHistory])

  const totalTickets = useMemo(() => {
    let total = 0;
    ticketHistory.forEach(ticket => {
      if (ticket.validationType !== 34) total++;
    });
    return total;
  }, [ticketHistory])

  const {refetch, isFetching} = useQuery({
    queryKey: ['getConsultTicket', ticketCode],
    queryFn: async () => {
      try {
        if (ticketHistory.some(ticket => ticket.ticketCode === ticketCode)) {
          toast({
            title: "Ticket ya agregado",
            variant: 'info',
            duration: 2000
          });
          inputRef.current.focus();
          setTicketCode('');
          return;
        }

        const response = await axios.get(Apis.CAJA + '/operation-ticket/valid-number/' + ticketCode);
        const ticketData = response.data;

        if (ticketData.exists) {
          toast({
            title: "Ticket agregado!",
            variant: 'success',
            duration: 2000
          });
          const ticketInfo = ticketData.ticket;
          const ticketObject = {
            ticketCode,
            validNumber: ticketInfo.validNumber,
            confId: ticketInfo.confId,
            machine: ticketInfo.machine,
            confIdRedeem: ticketInfo.confIdRedeem,
            machineRedeem: ticketInfo.machineRedeem,
            amount: ticketInfo.amount,
            status: ticketInfo.status,
            createdAt: ticketInfo.createdAt,
            redeemAt: ticketInfo.redeemAt,
            fondoIdRedeem: ticketInfo.fondoIdRedeem,
            fondoNameRedeem: ticketInfo.fondoNameRedeem,
            validationType: ticketInfo.validationType
          };

          setTicketHistory(prevHistory => [ticketObject,...prevHistory]);
        } else {
          toast({
            title: "Ticket no encontrado",
            variant: 'danger',
            duration: 2000
          });
        }

        inputRef.current.focus();
        setTicketCode('');
        return response.data;

      } catch (error) {
        let errorMessage = 'Ticket no encontrado';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        toast({
          title: errorMessage,
          variant: 'danger',
          duration: 2000
        });
        inputRef.current.focus();
        setTicketCode('');
        throw error;
      }
    },
    enabled: false,
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    refetch();
  };

  const columns = useMemo(
    () => [
      {
        header: 'Estado',
        accessorKey: 'getstatus',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: ({row}) => (getStatusText(row.original.status, row.original.validationType)),
      },
      {
        header: 'Numero de ticket',
        accessorKey: 'validNumber'
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount !== null ? formatNumber(row.amount * 0.01, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Tipo',
        id: 'validationType',
        accessorFn: row => row.validationType === 0 ? 'CASHOUT' : 'PROMOCIONAL',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Emitido en',
        id: 'machine',
        accessorFn: row => row.machine ? row.machine : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha de creación',
        id: 'createdAt',
        accessorFn: row => row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Canjeado en',
        id: 'redeemBy',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          info.row.original.confIdRedeem !== null && info.row.original.confIdRedeem !== 0 
            ? info.row.original.machineRedeem 
            : info.row.original.fondoNameRedeem
        )
      },
      {
        header: 'Fecha de canje',
        id: 'redeemAt',
        accessorFn: row => `${row.redeemAt ? format(parseISO(row.redeemAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: '',
        accessorKey: 'checkstatus',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (info.row.original.status === 0 ? <LuAlertCircle size={22} className='text-amber-400 animate-bounce' /> : null),
      },
    ]
    , [config]
  )

  return (
    <div className='flex flex-col flex-1'>
      <div className='flex flex-row items-center justify-center gap-4'>
        <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        <span className='font-light text-neutral-500'>CONSULTA DE TICKETS</span>
        <div className='h-[1px] bg-neutral-500 flex-1 flex' />
      </div>

      <div className='flex flex-col self-stretch flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>
        <div className='flex flex-row items-center gap-4'>
          <div className='flex flex-row items-center flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
            <div className='flex flex-col flex-1'>
              <span className='text-lg font-bold'>{totalTickets ? formatNumber(totalTickets, null) : '--'}</span>
              <span className='text-xs font-light'>Cantidad</span>
            </div>
            <div className='p-3 bg-neutral-900 rounded-xl'>
              <LuListOrdered size={20} />
            </div>
          </div>
          <div className='flex flex-row flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
            <div className='flex flex-col flex-1'>
              <span className='text-lg font-bold'>{totalAmount ? formatNumber(totalAmount*0.01, config.symbol) : '--'}</span>
              <span className='text-xs font-light'>Total</span>
            </div>
            <div className='p-3 bg-neutral-900 rounded-xl'>
              <LuBanknote size={20} />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
          <FormControl>
            <label>Ingrese el número de ticket</label>
            <Input
              placeholder="Nro. de ticket"
              type='text'
              id='auto-focus-input'
              ref={inputRef}
              value={ticketCode}
              autoFocus
              required
              autoComplete="off"
              onChange={(e) => setTicketCode(e.target.value)}
            />
          </FormControl>
          <Button size='lg' disabled={isFetching || ticketCode === ''} type="submit">{isFetching ? 'Cargando...' : 'Consultar'}</Button>
        </form>
        {ticketHistory.length > 0 && <ReactTable columns={columns} data={ticketHistory} />}
      </div>
    </div>
  )
}

export default TicketReview
