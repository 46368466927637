import React from 'react'
import {LuFileWarning} from 'react-icons/lu'
import {cn} from '../../../utils/general';

const EmptyState = ({title, subtitle, icon,className}) => {
  return (
    <div className={cn("flex flex-col items-center justify-center gap-4 text-neutral-500",className)}>
      <span className='text-lg font-bold'>{title}</span>
      {icon}
      <span className='text-sm font-light'>{subtitle}</span>
    </div>
  )
}

EmptyState.defaultProps = {
  title: 'No hay datos disponibles',
  subtitle: 'Parece que no hay registros de operaciones en este momento.',
  icon: <LuFileWarning size={48} />,
};

export default EmptyState