import React, {forwardRef} from "react";
import {Root as Accordion, Item as AccordionItem, Header as AccordionHeader, Trigger as AccordionTrigger, Content as AccordionContent} from "@radix-ui/react-accordion";
import {cn} from "../../../utils/general";
import {LuChevronDown} from "react-icons/lu";

const CustomAccordionItem = forwardRef(({className, ...props}, ref) => (
  <AccordionItem ref={ref} className={cn("border-b border-neutral-800", className)} {...props} />
));

CustomAccordionItem.displayName = "AccordionItem";

const CustomAccordionTrigger = forwardRef(({className, children, ...props}, ref) => (
  <AccordionHeader className="flex">
    <AccordionTrigger
      ref={ref}
      className={cn(
        "flex flex-1 items-center justify-between py-4 font-medium transition-all [&[data-state=open]>svg]:rotate-180",
        className
      )}
      {...props}
    >
      {children}
      <LuChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
    </AccordionTrigger>
  </AccordionHeader>
));

CustomAccordionTrigger.displayName = AccordionTrigger.displayName;

const CustomAccordionContent = forwardRef(({className, children, ...props}, ref) => (
  <AccordionContent
    ref={ref}
    className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn("", className)}>{children}</div>
  </AccordionContent>
));

CustomAccordionContent.displayName = AccordionContent.displayName;

export {Accordion, CustomAccordionItem as AccordionItem, CustomAccordionTrigger as AccordionTrigger, CustomAccordionContent as AccordionContent};
