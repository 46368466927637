import React, {useContext, useMemo} from 'react'
import {SiteContext} from '../../context/SiteContext';
import {useMutation, useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {LuBanknote, LuListOrdered} from 'react-icons/lu';
import {EmptyState, ReactTable} from '../../components/blocks';
import {format, parseISO} from 'date-fns';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {Button} from '../../components/atoms';
import {formatNumber} from '../../utils/general';
import {toast} from '../../hooks/useToast';

const HisHandpay = () => {

  const {fondo, config,printer} = useContext(SiteContext);

  const {data, isFetched} = useQuery({
    queryKey: ['getHandpays', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/operation-handpay/report/by-fondo?fondoId=' + fondo?.id)
      .then((res) => res.data),
    enabled: !!fondo?.id
  })

  const deleteElement = (id) => axios.delete(Apis.CAJA + '/operation-handpay/' + id);

  const printMutation = useMutation({
    mutationKey: ['print_handpay'],
    mutationFn: (id) => axios.get(Apis.CAJA + '/operation-handpay/' + id + '/print?printerId='+printer?.printerId),
    onSuccess: () => {
      toast({
        title: "Impresion realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_handpay', //mutateKey
    'getHandpays', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Juego',
        accessorKey: 'gameName'
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount ? formatNumber(row.amount, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName',
      },
      {
        header: 'Documento',
        accessorKey: 'customerDocument',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            <Button variant='outline' size='sm' onClick={() => deleteModal.handleClick(info.row.original.id)}>Eliminar</Button>
            <Button size='sm' onClick={() => printMutation.mutate(info.row.original.id)}>Imprimir</Button>
          </div>
        ),
      }
    ]
    , [deleteModal, config,printMutation]
  );

  return (
    <div className='flex flex-col flex-1 gap-4'>
      {isFetched &&
        <>
          <div className='flex flex-row items-center gap-4'>
            <div className='flex flex-row items-center flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
              <div className='flex flex-col flex-1'>
                <span className='text-lg font-bold'>{data?.quantity ? formatNumber(data.quantity, null) : '--'}</span>
                <span className='text-xs font-light'>Nro de Operaciones</span>
              </div>
              <div className='p-3 bg-neutral-900 rounded-xl'>
                <LuListOrdered size={20} />
              </div>
            </div>
            <div className='flex flex-row flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
              <div className='flex flex-col flex-1'>
                <span className='text-lg font-bold'>{data?.totalAmount ? formatNumber(data.totalAmount, config.symbol) : '--'}</span>
                <span className='text-xs font-light'>Total</span>
              </div>
              <div className='p-3 bg-neutral-900 rounded-xl'>
                <LuBanknote size={20} />
              </div>
            </div>
          </div>
          {data.data && data.data.length > 0 ?
            <ReactTable columns={columns} data={data.data} />
            :
            <EmptyState />
          }
        </>
      }
      <deleteModal.ConfirmationModal />
    </div>
  )
}

export default HisHandpay
