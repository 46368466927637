import React, {forwardRef} from "react";
import {cn} from "../../../utils/general";

const Input = forwardRef(({className, type, ...props}, ref) => {
  return (
    <input
      type={type}
      className={cn(
        "flex h-[50px] tracking-wide w-full rounded-2xl border border-neutral-600 bg-transparent px-6 py-4 text-lg font-medium ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-white/10 placeholder:font-light placeholder:text-xl focus-visible:outline-none focus-visible:border-neutral-400 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

export {Input};