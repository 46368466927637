import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Bill, CallbackPage, CashBack, CloseTurn, Historial, LogoutPage, Operations, Refills, Selector, TicketMachine, TicketReview, Transfers} from './pages';
import {Layout} from './components/blocks';
import WauthProvider from './auth/WauthProvider';
import {SiteProvider} from './context/SiteContext';
import {Toaster} from './components/blocks/toaster';

function App() {
  return (
    <WauthProvider
      domain={'https://accounts.wargos.cloud'}
      clientId={'DBqTwXTGkuoVzv1YojUym0Z7bQseGx2S'}
      redirectUri={window.location.origin + "/callback"}
    >
      <SiteProvider>
        <Toaster />
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Operations />} />
            <Route path="close-turn" element={<CloseTurn />} />
            <Route path="bill" element={<Bill />} />
            <Route path="transfers" element={<Transfers />} />
            <Route path="refills" element={<Refills />} />
            <Route path="ticket-review" element={<TicketReview />} />
            <Route path="ticket-machine" element={<TicketMachine />} />
            <Route path="historial" element={<Historial />} />
            <Route path="cashback" element={<CashBack />} />
            <Route path="*" element={<Operations />} />
          </Route>
          <Route path="selector" element={<Selector />} />
          <Route path="callback" element={<CallbackPage />} />
          <Route path="logout" element={<LogoutPage />} />
        </Routes>
      </SiteProvider>
    </WauthProvider>
  );
}

export default App;
