import React, {useEffect} from "react";
import {useWauth} from "../../auth/WauthProvider";
import {FaSpinner} from "react-icons/fa";

const LogoutPage = () => {

  localStorage.removeItem("currentUser");
  localStorage.removeItem("name");
  localStorage.removeItem("salaId");
  localStorage.removeItem("sala");
  localStorage.removeItem("fondo");

  const {logout} = useWauth()

  useEffect(() => {
    logout({returnTo: window.location.origin})
    // eslint-disable-next-line
  }, [])

  return (
    <div className='flex flex-col items-center justify-center w-full h-screen gap-4 select-none text-neutral-950 animate-pulse'>
      <FaSpinner className='animate-spin' size={24} />
      <span className="text-sm">Cerrando sesión...</span>
    </div>
  )
}

export default LogoutPage
