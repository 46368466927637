import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, FormControl, Input} from '../../components/atoms'
import {TbAlertCircleFilled, TbCircleCheckFilled, TbCircleXFilled} from 'react-icons/tb';
import {useQuery} from '@tanstack/react-query'
import axios from 'axios';
import {SiteContext} from '../../context/SiteContext';
import {Apis} from '../../utils/apis';
import {format, isValid, parseISO} from "date-fns";
import {FaSpinner} from 'react-icons/fa';
import {es} from 'date-fns/locale';
import CreateTicket from "../../components/blocks/create-ticket";
import {ComboClient} from "../../components/blocks";

const TicketDetails = ({ticketInfo}) => {

  const isValidDate = (date) => {
    const parsedDate = parseISO(date);
    return isValid(parsedDate);
  }

  const formatDate = (date) => {
    if (isValidDate(date)) {
      return format(parseISO(date), "dd MMM hh:mm a", {locale: es});
    }
    return '--';
  }

  return (
    <div className='flex flex-row gap-6'>
      <div className='flex flex-col flex-1 gap-4 p-4 border rounded-2xl border-neutral-500'>
        <div className='flex flex-row items-center gap-2'>
          <span>Emitido en:</span>
          <strong> {ticketInfo.machine ? ticketInfo.machine : '--'}</strong>
        </div>
        <div className='flex flex-row items-center gap-2'>
          <span>Fecha de emisión:</span>
          <strong>{ticketInfo.emitido ? formatDate(ticketInfo.emitido) : '--'}</strong>
        </div>
        <div className='flex flex-row items-center gap-2'>
          {ticketInfo.status !== 2 ?
            <>
              <span>Fecha de canje:</span>
              <strong>{ticketInfo.redeemAt ? formatDate(ticketInfo.redeemAt) : '--'}</strong>
            </>
            :
            <>
              <span>Fecha de expiración:</span>
              <strong>{ticketInfo.redeemAt ? formatDate(ticketInfo.redeemAt) : '--'}</strong></>
          }
        </div>
        {ticketInfo.status === 1 &&
          <div className='flex flex-row items-center gap-2'>
            <span>Canjeado en:</span>
            <strong> {ticketInfo.confIdRedeem === null ? ticketInfo.fondoNameRedeem : ticketInfo.machineRedeem}</strong>
          </div>
        }
      </div>
      <div className='flex flex-col flex-1 gap-4'>
        <div
          className='flex flex-col items-center justify-center flex-1 gap-2 p-4 rounded-2xl bg-amber-200 text-neutral-950'>
          <span>{ticketInfo.status === 0 ? 'Monto a pagar' : 'Monto'}</span>
          <span
            className='text-3xl font-extrabold'>S/ {Number((ticketInfo?.monto ? ticketInfo?.monto : 0) * 0.01).toFixed(2)}</span>
        </div>
        {ticketInfo.status === 2 &&
          <Button>Habilitar</Button>
        }
      </div>
    </div>
  );
};

const Redeemtickets = ({onFocusInput}) => {

  const {fondo} = useContext(SiteContext);
  const [ticketCode, setTicketCode] = useState('');
  const [ticketData, setTicketData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [isValidated, setIsValidated] = useState(false);

  const {refetch, isFetching} = useQuery({
    queryKey: ['getTicketRedeem'],
    queryFn: async () => {
      const response = await axios.post(Apis.CAJA + '/operation-ticket', {
        validNumber: ticketCode,
        fondoId: fondo.id,
        customerId: selectedCustomer?.id || null
      });
      setTicketData(response.data);
      return response.data;
    },
    enabled: false,
    gcTime: 1
  })

  useEffect(() => {
    if (onFocusInput) {
      onFocusInput(() => {
        inputRef.current?.focus(); 
      });
    }
  }, [onFocusInput]);

  const getMessageByTicketStatus = (message, code, ticketInfo) => {
    const statusIcons = {
      notFound: <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>,
      alreadyRedeemed: <TbAlertCircleFilled size={28} className='text-red-500 animate-bounce'/>,
      expired: <TbAlertCircleFilled size={28} className='text-amber-500 animate-bounce'/>,
      notRedeemable: <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>,
      notPermission: <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>,
    };

    const messages = {
      3: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>El ticket no existe</span>
            {statusIcons.notFound}
          </div>
          <span>Vuelva a ingresar el número de ticket o verifique que está bien escrito</span>
        </>
      ),
      1: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>{message}</span>
            {statusIcons.alreadyRedeemed}
          </div>
          <TicketDetails ticketInfo={ticketInfo}/>
        </>
      ),
      2: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>{message}</span>
            {statusIcons.expired}
          </div>
          <TicketDetails ticketInfo={ticketInfo}/>
        </>
      ),
      4: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>El ticket no es canjeable</span>
            {statusIcons.notRedeemable}
          </div>
          <span>Revise si el ticket es promocional o si es un recibo de un pago manual.</span>
        </>
      ),
      7: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>Necesita un cliente para canjear este ticket</span>
            {statusIcons.notPermission}
          </div>
          <span>Revise si el ticket tiene un monto mayor al configurado.</span>
        </>
      ),
      default: (
        <>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>Hubo un error</span>
            {statusIcons.notFound}
          </div>
          <span>---</span>
        </>
      ),
    };

    return messages[code] || messages.default;
  };

  const renderTicketResponse = (ticketData) => {
    if (ticketData?.success) {
      return (
        <div className='flex flex-col gap-6'>
          <div className='flex flex-row items-center gap-2'>
            <span className='text-lg font-bold'>Ticket canjeado exitosamente</span>
            <TbCircleCheckFilled size={28} className='text-lime-400 animate-bounce'/>
          </div>
          <TicketDetails ticketInfo={ticketData.ticketInfo}/>
        </div>
      );
    } else {
      return (
        getMessageByTicketStatus(ticketData?.message, ticketData?.code, ticketData?.ticketInfo)
      )
    }
  };

  const handleTicketValidation = (event) => {
    event.preventDefault();
    if (ticketCode.length > 18 || ticketCode.length < 18) {
      setErrorMessage('Longitud de ticket inválida');
      return;
    }
    setIsValidated(true);
    setErrorMessage('');
    refetch();
  };

  const handleClear = (event) => {
    event.preventDefault();
    inputRef.current.focus();
    setTicketCode('');
    setIsValidated(false);
    setTicketData(null)
    setErrorMessage('');
    setSelectedCustomer(null);
  };

  useEffect(() => {
    if (ticketData?.code === 3) {
      setIsValidated(false);
      setErrorMessage(ticketData?.message);
    }
    if (ticketData?.code === 7) {
      setIsValidated(false);
      setErrorMessage(ticketData?.message);
    }
  }, [ticketData]);

  return (
    <>
      <div className='flex flex-row items-center justify-center gap-4 mt-2 mb-4'>
        <div className='h-[1px] bg-neutral-500 flex-1 flex'/>
        <span className='font-light text-neutral-500'>CANJEAR TICKET</span>
        <div className='h-[1px] bg-neutral-500 flex-1 flex'/>
      </div>
      <div className='flex flex-col self-stretch flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>
        <form onSubmit={isValidated ? handleClear : handleTicketValidation}>
          <div className='flex flex-col gap-6'>

            <FormControl>
              <label>Ingrese el número de ticket</label>
              <Input
                placeholder="Nro. de ticket"
                type='text'
                id='auto-focus-input'
                ref={inputRef}
                value={ticketCode}
                autoFocus
                required
                autoComplete="off"
                onChange={(e) => setTicketCode(e.target.value)}
              />

              {
                ticketData?.code === 7 &&
                <ComboClient setSelectedCustomer={setSelectedCustomer} selectedCustomer={selectedCustomer}/>
              }

            </FormControl>
            {errorMessage && ( // Mostrar el mensaje de error si existe
              <div className='flex flex-row items-center gap-2'>
                <span className='text-lg font-bold'>{errorMessage}</span>
                <TbCircleXFilled size={28} className='text-red-500 animate-bounce'/>
              </div>
            )}
            {isValidated && (
              <div className='flex flex-col gap-4'>
                {isFetching ?
                  <div className='flex flex-col items-center justify-center w-full gap-4 py-6 select-none animate-pulse'>
                    <FaSpinner className='animate-spin' size={24}/>
                    <span>Cargando...</span>
                  </div>
                  :
                  renderTicketResponse(ticketData)}
              </div>
            )}
            <Button
              size='lg'
              type="submit"
              variant={isValidated ? 'secondary' : 'default'}
              disabled={isFetching}
            >
              {isValidated ? 'Limpiar' : 'Canjear ticket'}
            </Button>

            {
              ticketData?.code === 3 &&
              <div style={{display: 'flex', alignItems: 'center'}}>
                <CreateTicket ticketCode={ticketCode}/>
                <Button
                  style={{marginLeft: '5px'}}
                  size='sm'
                  type="submit"
                  variant={'default'}
                  onClick={handleClear}
                >
                  Limpiar
                </Button>
              </div>
            }
          </div>
        </form>
      </div>
    </>
  )
}

export default Redeemtickets
