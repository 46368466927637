import React, {useState} from 'react'
import styles from './historial.module.css';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../components/atoms';
import {RiHandCoinLine} from 'react-icons/ri';
import {LuBanknote} from 'react-icons/lu';
import {BiMoneyWithdraw} from 'react-icons/bi';
import HistorialOutput from './historialOutput';
import HistorialInput from './historialInput';
import HistorialOthers from './historialOthers';

const Historial = () => {

  const [activeTab, setActiveTab] = useState('premios');

  return (
    <div className='flex flex-col self-stretch flex-1 gap-4'>
      <div className='flex flex-row items-center justify-center gap-4'>
        <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        <span className='font-light text-neutral-500'>HISTORIAL DE MOVIMIENTOS</span>
        <div className='h-[1px] bg-neutral-500 flex-1 flex' />
      </div>
      <Tabs defaultValue={activeTab} value={activeTab} onValueChange={setActiveTab} className='flex flex-col flex-1'>
        <TabsList>
          <TabsTrigger value="premios">
            <div className={styles.tabsTrigger}>
              <RiHandCoinLine size={24} />
              <span>Premios</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="venta">
            <div className={styles.tabsTrigger}>
              <LuBanknote size={24} />
              <span>Venta</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="otros">
            <div className={styles.tabsTrigger}>
              <BiMoneyWithdraw size={24} />
              <span>Gastos</span>
            </div>

          </TabsTrigger>
        </TabsList>
        <TabsContent value="premios">
          <HistorialOutput />
        </TabsContent>
        <TabsContent value="venta">
          <HistorialInput />
        </TabsContent>
        <TabsContent value="otros">
          <HistorialOthers />
        </TabsContent>

      </Tabs>

    </div>
  )
}

export default Historial
