import React, {useContext, useEffect, useState} from 'react'
import {SiteContext} from '../../context/SiteContext'
import {LuCalendarDays, LuUser} from 'react-icons/lu'
import {BiMoneyWithdraw} from 'react-icons/bi'
import {Button, FormControl, Input} from '../../components/atoms'
import {ComboFondo, DialogConfirm, DialogMoney} from '../../components/blocks'
import {useMutation} from '@tanstack/react-query'
import axios from 'axios'
import {Apis} from '../../utils/apis'
import {toast} from '../../hooks/useToast'
import {useNavigate} from 'react-router-dom'
import {cn, formatNumber} from '../../utils/general'

const CloseTurn = () => {

  const {userInfo, fondo, config,printer,salaId} = useContext(SiteContext);
  const [data, setData] = useState(null);
  const [amount, setAmount] = useState('');
  const [selectedFondo, setSelectedFondo] = useState(null);
  const [openMoney, setOpenMoney] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const navigate = useNavigate();

  const getPreArqueoMutation = useMutation({
    mutationKey: ['getPreArqueo'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/arqueo/pre/v2', data),
    onSuccess: (data) => {
      setData(data.data);
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleGetPreArqueo = () => {
    if (amount >=0) {
      getPreArqueoMutation.mutate({
        fondoId: fondo.id,
        foundedAmount: amount
      });
    }
  };

  useEffect(() => {
    setAmount(0);
    handleGetPreArqueo();
    //eslint-disable-next-line
  }, []);

  const closeTurnMutation = useMutation({
    mutationKey: ['close_turn'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/fondos/' + fondo.id + '/arqueo-and-close', data),
    onSuccess: () => {
      navigate('/logout');
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleConfirmCloseTurn = () => {
    if (amount >= 0) {
      closeTurnMutation.mutate({
        fondoId: fondo.id,
        foundedAmount: amount,
        targetFondoId: selectedFondo?.id,
        printerId: printer?.printerId,
        salaId: salaId
      });
    }
  };

  return (
    <div className='flex flex-col flex-1'>
      <div className='flex flex-row items-center gap-4 mb-4 max-[1024px]:hidden'>
        <div className='flex flex-row items-center flex-1 gap-2 p-4 border rounded-2xl border-neutral-800'>
          <div className='flex flex-col flex-1'>
            <span className='font-bold'>{userInfo?.user?.name + ' ' + userInfo?.user?.lastName}</span>
            <span className='text-xs font-light'>Cajero</span>
          </div>
          <div className='p-4 bg-neutral-800 rounded-2xl'>
            <LuUser size={20} />
          </div>
        </div>
        <div className='flex flex-row flex-1 gap-2 p-4 border rounded-2xl border-neutral-800'>
          <div className='flex flex-col flex-1'>
            <span className='font-bold'>{fondo?.name}</span>
            <span className='text-xs font-light'>Fondo</span>
          </div>
          <div className='p-4 bg-neutral-800 rounded-2xl'>
            <BiMoneyWithdraw size={20} />
          </div>
        </div>
        <div className='flex flex-row flex-1 gap-2 p-4 border rounded-2xl border-neutral-800'>
          <div className='flex flex-col flex-1'>
            <span className='font-bold'>{fondo?.period}</span>
            <span className='text-xs font-light'>Periodo</span>
          </div>
          <div className='p-4 bg-neutral-800 rounded-2xl'>
            <LuCalendarDays size={20} />
          </div>
        </div>
      </div>

      <div className='flex flex-row items-center justify-center gap-4 mb-4'>
        <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        <span className='font-light text-neutral-500'>CIERRE DE TURNO</span>
        <div className='h-[1px] bg-neutral-500 flex-1 flex' />
      </div>

      <div className='flex flex-row self-stretch gap-4'>

        <div className='flex flex-col flex-1 gap-4'>
          <div className='flex flex-col flex-1 gap-4 p-4 bg-amber-200 text-neutral-950 rounded-2xl'>
            <span className='text-lg font-bold'>Operaciones</span>
            <div className='flex flex-col gap-4'>
              <span className='font-bold'>Entradas</span>
              <div className='flex flex-col gap-4 px-2'>
                <div className='flex flex-row self-stretch gap-4'>
                  <span className='flex-1'>Venta de tickets ({data?.ticketVentaQuantity})</span>
                  <span
                    className='text-right'>{data?.ticketVenta !== null ? formatNumber(data?.ticketVenta, config.symbol) : '--'}</span>
                </div>
                <div className='flex flex-row self-stretch gap-4'>
                  <span className='flex-1'>Billetero ({data?.billQuantity})</span>
                  <span
                    className='text-right'>{data?.bill !== null ? formatNumber(data?.bill, config.symbol) : '--'}</span>
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <span className='font-bold'>Salidas</span>
              <div className='flex flex-col gap-4 px-2'>
                <div className='flex flex-row self-stretch gap-4'>
                  <span className='flex-1'>Canje de tickets ({data?.ticketRedeemQuantity})</span>
                  <span
                    className='text-right'>{data?.ticket !== null ? formatNumber(data?.ticket, config.symbol) : '--'}</span>
                </div>
                <div className='flex flex-row self-stretch gap-4'>
                  <span className='flex-1'>Pagos manuales ({data?.handpayQuantity})</span>
                  <span
                    className='text-right'>{data?.handpay !== null ? formatNumber(data?.handpay, config.symbol) : '--'}</span>
                </div>
                <div className='flex flex-row self-stretch gap-4'>
                  <span className='flex-1'>Tarjetas ({data?.cardpayQuantity})</span>
                  <span
                    className='text-right'>{data?.cardpay !== null ? formatNumber(data?.cardpay, config.symbol) : '--'}</span>
                </div>
                <div className='flex flex-row self-stretch gap-4'>
                  <span className='flex-1'>Rellenos ({data?.refillQuantity})</span>
                  <span
                    className='text-right'>{data?.refill !== null ? formatNumber(data?.refill, config.symbol) : '--'}</span>
                </div>
                <div className='flex flex-row self-stretch gap-4'>
                  <span className='flex-1'>Otros ({data?.otherQuantity})</span>
                  <span
                    className='text-right'>{data?.other !== null ? formatNumber(data?.other, config.symbol) : '--'}</span>
                </div>
              </div>
            </div>
            <span className='border-b border-dashed border-neutral-700'/>
            <div className='flex flex-row self-stretch gap-4 px-2 pb-2'>
              <span className='flex-1 text-lg font-bold'>Total</span>
              <span
                className='text-lg font-bold text-right'>{data?.totalOperationNoTransfer !== null ? formatNumber(data?.totalOperationNoTransfer, config.symbol) : '--'}</span>
            </div>
          </div>
          <div className='flex flex-col flex-1 gap-4 p-4 bg-amber-200 text-neutral-950 rounded-2xl'>
            <span className='text-lg font-bold'>Operaciones T.C.</span>
            <div className='flex flex-row self-stretch gap-4 px-2 pb-2'>
              <span className='flex-1'>Dolares</span>
              <span
                className='text-right'>{data?.currencyArqueo[0] !== null ? formatNumber(data?.currencyArqueo[0]?.amount, '$') : '--'}</span>
            </div>
          </div>
        </div>
        <div className='flex flex-col self-start flex-1 gap-6 p-4 border border-neutral-800 rounded-2xl'>
          <span className='text-lg font-bold'>Monto cierre</span>
          <FormControl>
            <label>Ingrese monto</label>
            <Input
              placeholder="00.0"
              type='number'
              className='text-2xl font-semibold cursor-pointer'
              autoComplete="off"
              value={amount}
              readOnly
              required
              onChange={(e) => setAmount(e.target.value)}
              onClick={() => setOpenMoney(true)}
            />
          </FormControl>
          <FormControl>
            <label>Seleccione fondo</label>
            <ComboFondo selectedFondo={selectedFondo} setSelectedFondo={setSelectedFondo}/>
          </FormControl>
          <Button variant='secondary' className='mb-4' onClick={handleGetPreArqueo}>
            Pre arqueo
          </Button>
        </div>
        <div className='flex flex-col flex-1 gap-4'>
          <div className='flex flex-col items-end gap-4 p-4 border border-neutral-800 rounded-2xl'>
            <span className='text-lg font-bold'>Cierre de turno soles</span>
            <div className='flex flex-col items-end gap-1 px-2'>
              <span
                className='text-xl font-bold'>{data?.transfer !== null ? formatNumber(data?.transfer, config.symbol) : '--'}</span>
              <span className='text-sm font-light text-neutral-500'>Solicitudes / incrementos</span>
            </div>
            <div className='flex flex-col items-end gap-1 px-2'>
              <span
                className='text-xl font-bold'>{data?.foundedAmount !== null ? formatNumber(data?.foundedAmount, config.symbol) : '--'}</span>
              <span className='text-sm font-light text-neutral-500'>Monto de cierre</span>
            </div>
          </div>
          <div className='flex flex-col items-end gap-4 p-4 border border-neutral-800 rounded-2xl'>
            <span className='text-lg font-bold'>Cierre de turno dolares</span>
            <div className='flex flex-col items-end gap-1 px-2'>
              <span
                className='text-xl font-bold'>{data?.currencyArqueo[0] !== null ? formatNumber(data?.currencyArqueo[0]?.amountFound, '$') : '--'}</span>
              <span className='text-sm font-light text-neutral-500'>Monto de cierre</span>
            </div>
          </div>
          <div className={cn('flex flex-col items-end gap-4 p-4 rounded-2xl text-neutral-950',
            {
              'bg-lime-200': data && data.diff >= 0,
              'bg-red-400': data && data.diff < 0,
              'bg-transparent border border-neutral-800 text-white': !data
            })}>
            <span className='text-lg font-bold'>Faltante / Sobrante</span>
            <div className='flex flex-row items-center self-stretch gap-2 p-2'>
              <span className='flex-1 text-sm font-light'>Soles</span>
              <span
                className='text-2xl font-bold text-right'>{data?.diff !== null ? formatNumber(data?.diff, config.symbol) : '--'}</span>
            </div>
          </div>
          <Button size='lg' disabled={!getPreArqueoMutation.isSuccess} onClick={() => setOpenConfirmDialog(true)}>Cerrar
            Turno</Button>
        </div>

      </div>

      <DialogMoney
        open={openMoney}
        setOpen={setOpenMoney}
        setAmount={setAmount}
      />
      <DialogConfirm
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={handleConfirmCloseTurn}
        body={
          <div className='flex flex-col gap-6 py-4'>
            <span>¿Estás seguro de que deseas cerrar el turno?</span>
            <div className='flex flex-col gap-4'>
              <div className="flex flex-row">
                <span className='flex-1'>Monto de cierre: </span>
                <span
                  className='text-lg font-bold text-right'>{data && data.foundedAmount ? `${config.symbol} ${Number(data.foundedAmount).toFixed(2)}` : '--'}</span>
              </div>
              <div className="flex flex-row">
                <span className='flex-1'>Faltante / sobrante: </span>
                <span
                  className='text-lg font-bold text-right'>{data && data.diff ? `${config.symbol} ${Number(data.diff).toFixed(2)}` : '--'}</span>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default CloseTurn
