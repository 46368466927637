import React, {useContext} from 'react'
import {Button} from '../../components/atoms'
import cashbackTito from '../../assets/cashback tito.png'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {format, parseISO} from 'date-fns';
import {toast} from '../../hooks/useToast';
import {SiteContext} from '../../context/SiteContext';
import {EmptyState} from '../../components/blocks';
import useConfirmDialog from '../../hooks/useConfirmDialog';

const CashBack = () => {

  const {fondo, printer} = useContext(SiteContext);
  const queryClient = useQueryClient();

  const {data} = useQuery({
    queryKey: ['getCashbacks'],
    queryFn: async () => await axios.get(Apis.PC + '/redeems?cashback=true')
      .then((res) => res.data)
  })

  const deleteElement = (id) => axios.post(Apis.PC + '/redeems/' + id + '/cancel');

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_cashback', //mutateKey
    'getCashbacks', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const cashbackMutation = useMutation({
    mutationFn: async ({price, id}) => {
      await axios.post(Apis.PC + `/redeems/${id}/print-cashback`, {
        fondoId: fondo.id,
        computerId: printer?.computerId,
      });
    },
    onSuccess: () => {
      toast({
        title: 'Operación realizada!',
        variant: 'success',
        duration: 2000,
      });
      queryClient.invalidateQueries(['getCashbacks']);
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000,
      });
    },
  });

  const redeemModal = useConfirmDialog(
    '¿Deseas canjear este producto?',
    'op_cashback_sale', // mutateKey
    'getCashbacks', // volver a llamar para refrescar la data
    ({price, id}) => cashbackMutation.mutate({price, id}) // acción para ejecutar en el mutate
  );

  return (
    <div className='flex flex-col self-stretch flex-1 gap-4'>
      <div className='flex flex-row items-center justify-center gap-4'>
        <div className='h-[1px] bg-neutral-500 flex-1 flex'/>
        <span className='font-light text-neutral-500'>CASHBACK PENDIENTES</span>
        <div className='h-[1px] bg-neutral-500 flex-1 flex'/>
      </div>
      {data && data.length > 0 ?
        <div className='flex flex-col self-stretch gap-6 p-4 bg-neutral-800 rounded-2xl'>
          <div className='flex flex-row items-center flex-1 gap-4 text-neutral-500'>
            <span className='flex-1'>PRODUCTO</span>
            <span className='flex-1'>CLIENTE</span>
            <span className='flex-1 text-center'>FECHA</span>
            <span className='flex-1 text-center'>IMAGEN</span>
            <div className='flex-1 text-center'>ACCIONES</div>
          </div>
          {data?.filter(item => item.state === "GENERADO").map((redeem) => (
            <div className='flex flex-row items-center flex-1 gap-4'>
              <span className='flex-1 text-xl font-bold'>{redeem.productName}</span>
              <span className='flex-1'>{redeem.customerName}</span>
              <span
                className='flex-1 text-center'>{redeem.createdAt ? format(parseISO(redeem.createdAt), "dd/MM hh:mm a") : '--'}</span>
              <img alt='ticket' src={cashbackTito} style={{height: '90px', width: 'auto'}}></img>
              <div className='flex flex-row items-center flex-1 gap-2'>
                <Button variant='outline' onClick={() => deleteModal.handleClick(redeem.id)}>Cancelar</Button>
                <Button onClick={() => redeemModal.handleClick({price: redeem.productPrice, id: redeem.id})}>
                  Canjear
                </Button>
              </div>
            </div>
          ))}
        </div>
        :
        <EmptyState/>
      }
      <deleteModal.ConfirmationModal/>
      <redeemModal.ConfirmationModal/>
    </div>
  )
}

export default CashBack
