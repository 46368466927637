import React, {useContext} from 'react'
import {useNavigate} from 'react-router-dom';
import {SiteContext} from '../../context/SiteContext'
import {useWauth} from '../../auth/WauthProvider';
import styles from './selector.module.css';
import {cn} from '../../utils/general';
import {Button} from '../../components/atoms';

const Selector = () => {

  const navigate = useNavigate();
  const {isAuthenticated, loginWithRedirect} = useWauth();
  const {setSalaId, setSala, userInfo} = useContext(SiteContext)

  if (!isAuthenticated) {
    loginWithRedirect();
    return;
  }

  const logout = () => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("name");
    localStorage.removeItem("salaId");
    localStorage.removeItem("sala");
    localStorage.removeItem("fondo");
    navigate('/logout');
  };

  const select = (item) => {
    setSalaId(item.id);
    setSala(item);
    localStorage.setItem('salaId', item.id)
    localStorage.setItem('sala', JSON.stringify(item))

    const searchParams = new URLSearchParams(window.location.search);
    const redirect = searchParams.get('redirect');
    if (redirect) {
      navigate(redirect);
    } else {
      navigate('/');
    }
  };

  const getLogoURL = (logoUrl) => {
    if (logoUrl === undefined || logoUrl === null || logoUrl === '') {
      return 'https://res.cloudinary.com/dghqjv5az/image/upload/v1669488290/mix/logossala/logo_xd33n7.png'
    }
    return 'https://res.cloudinary.com/dghqjv5az/image/upload/c_fill,h_120/linkedwgt/' + logoUrl
  }


  return (
    <div className='flex flex-col items-center w-full gap-6 p-6 bg-neutral-50'>
      <span>Seleccione su sala</span>
      <Button onClick={logout}>Cerrar sesión</Button>
        <div className={styles.selectorContainer}>
          {userInfo?.salas?.map(item =>
            <div 
              key={item.id} 
              className={cn(styles.selectorItem,'group')} 
              onClick={() => select(item)}
            >
              <div className={styles.selectorItemImg}>
                <img 
                  src={getLogoURL(item.logo)} 
                  alt='logo' 
                  width='auto' 
                  height='auto' 
                  className='max-h-[120px]'
                />
              </div>
              <span className={cn(styles.selectorItemText,'group-hover:scale-105')}>{item.name}</span>
            </div>
          )}
        </div>
    </div>
  )
}

export default Selector