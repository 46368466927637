import React, {useContext, useState} from 'react'
import {SiteContext} from '../../../context/SiteContext';
import {Apis} from '../../../utils/apis';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query'
import {Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle, FormControl, Input} from '../../atoms';
import {LuChevronDown, LuUser} from 'react-icons/lu';
import {FiEdit} from 'react-icons/fi';

const ComboClient = ({selectedCustomer, setSelectedCustomer}) => {

  const {salaId} = useContext(SiteContext);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const {data: searchResults = [], refetch} = useQuery({
    queryKey: ['getCustomers', query],
    queryFn: async () => {
      if (query.length >= 3) {
        const response = await axios.get(`${Apis.SEARCH}/elastic?query=${query}&salaId=${salaId}`);
        return response.data;
      } else {
        return [];
      }
    }
  })

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setOpen(false);
  };

  const handleChangeCustomer = () => {
    setSelectedCustomer(null);
    setQuery('');
    setOpen(true);
    refetch();
  };

  return (
    <>
      <FormControl>
        {selectedCustomer ? (
          <div className='flex flex-row items-center'>
            <div className='flex flex-row items-center flex-1 gap-2'>
              <div className='flex items-center justify-center bg-white rounded-xl text-neutral-950 h-[50px] w-[50px]'>
                <LuUser size={24} />
              </div>
              <div className='flex flex-col'>
                <span className='text-lg font-bold'> {selectedCustomer.fullTotalName}</span>
                <span className='text-sm'> {selectedCustomer.document}</span>
              </div>
            </div>
            <Button variant='outline' onClick={handleChangeCustomer}><FiEdit size={20} /> Cambiar</Button>
          </div>
        ) : (
          <>
            <label>Seleccione Cliente</label>
            <Button
              variant="outline"
              className='justify-between w-full'
              size='lg'
              onClick={() => setOpen(true)}
            >
              <span className='text-lg text-neutral-500'>Buscar...</span>
              <LuChevronDown className="w-5 h-5 opacity-50 shrink-0" />
            </Button>
          </>
        )}
      </FormControl>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogOverlay />
        <DialogContent className='flex flex-col gap-4'>
          <DialogHeader>
            <DialogTitle>Seleccione un cliente</DialogTitle>
            <DialogDescription>
              Ingrese al menos 3 letras de su documento o nombre
            </DialogDescription>
          </DialogHeader>
          <Input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Buscar cliente..."
          />

          <div className='flex flex-col max-h-[300px] overflow-y-auto overflow-x-hidden'>
            {searchResults.length > 0 ?
              searchResults.map((customer) => (
                <div
                  key={customer.id}
                  className='py-4 mx-4 text-lg border-b cursor-pointer border-neutral-500'
                  onClick={() => handleCustomerSelect(customer)}
                >
                  {customer.fullTotalName}
                </div>
              ))
              :
              <div className="mx-4 text-base text-center text-neutral-500">
                {query.length > 2 && searchResults.length === 0 && 'No hay resultados'}
              </div>
            }
          </div>
        </DialogContent>

      </Dialog>
    </>
  )
}

export default ComboClient
