import React, {useContext, useMemo} from 'react'
import {SiteContext} from '../../context/SiteContext';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {LuBanknote, LuListOrdered} from 'react-icons/lu';
import {format, parseISO} from 'date-fns';
import {Button} from '../../components/atoms';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {EmptyState, ReactTable} from '../../components/blocks';
import {formatNumber} from '../../utils/general';

const HisExchangeRate = () => {

  const {fondo,config} = useContext(SiteContext);

  const {data, isFetched} = useQuery({
    queryKey: ['getExchangeRates', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/operation-exchange-rate?fondoId=' + fondo?.id)
      .then((res) => res.data),
    enabled: !!fondo?.id
  })

  const deleteElement = (id) => axios.delete(Apis.CAJA + '/operation-other/' + id);

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_exchangeRate', //mutateKey
    'getExchangeRates', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'De',
        id: 'enteredAmount',
        accessorFn: row => `${(row.enteredAmount ? formatNumber(row.enteredAmount,'$') : '--')}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Cambio',
        id: 'rate',
        accessorFn: row => `${row.rate !== '' ? row.rate.toFixed(2) : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'A',
        id: 'amountDelivered',
        accessorFn: row => `${(row.amountDelivered ? formatNumber(row.amountDelivered,config.symbol) : '--')}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            <Button variant='outline' size='sm' onClick={() => deleteModal.handleClick(info.row.original.id)}>Eliminar</Button>
            <Button size='sm'>Imprimir</Button>
          </div>
        ),
      }
    ],
    [deleteModal,config]
  );

  return (
    <div className='flex flex-col self-stretch flex-1 gap-4 bg-neutral-800 rounded-2xl'>
      {isFetched &&
      <>
        <div className='flex flex-row items-center gap-4'>
          <div className='flex flex-row items-center flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
            <div className='flex flex-col flex-1'>
              <span className='text-lg font-bold'>{data?.quantity ? formatNumber(data.quantity,null) : '--'}</span>
              <span className='text-xs font-light'>Nro de Operaciones</span>
            </div>
            <div className='p-3 bg-neutral-900 rounded-xl'>
              <LuListOrdered size={20} />
            </div>
          </div>
          <div className='flex flex-row flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
            <div className='flex flex-col flex-1'>
              <span className='text-lg font-bold'>{data?.totalAmount ? formatNumber(data.totalAmount,config.symbol) : '--'}</span>
              <span className='text-xs font-light'>Total</span>
            </div>
            <div className='p-3 bg-neutral-900 rounded-xl'>
              <LuBanknote size={20} />
            </div>
          </div>
        </div>
        {data.data && data.data.length > 0 ?
          <ReactTable columns={columns} data={data.data} />
          :
          <EmptyState />
        }
      </>
      }
      <deleteModal.ConfirmationModal />
    </div>
  )
}

export default HisExchangeRate