import React, {useContext, useEffect, useState} from 'react'
import {Button, FormControl, Input} from '../../components/atoms'
import {ComboCurrency} from '../../components/blocks';
import {SiteContext} from '../../context/SiteContext';
import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {toast} from '../../hooks/useToast';

const ExchangeRate = () => {

  const {fondo} = useContext(SiteContext);
  const [enteredAmount, setEnteredAmount] = useState('');
  const [exchangeValue, setExchangeValue] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const exchangeMutation = useMutation({
    mutationKey: ['op_exchange'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/operation-exchange-rate', data),
    onSuccess: () => {
      setEnteredAmount('')
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = () => {
    exchangeMutation.mutate({
      enteredAmount: enteredAmount,
      exchangeRateId: selectedCurrency?.id,
      fondoId: fondo.id,
    });
  };

  useEffect(() => {
    if (enteredAmount === '') {
      setExchangeValue('');
    } else if (selectedCurrency && selectedCurrency.rate !== undefined) {
      setExchangeValue((parseFloat(enteredAmount) * parseFloat(selectedCurrency.rate)).toFixed(2));
    }
  }, [enteredAmount, selectedCurrency]);

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <div className='flex flex-col self-stretch gap-6 pt-4 bg-neutral-800 rounded-2xl'>
        <FormControl>
          <label>Ingrese monto</label>
          <Input
            placeholder="00.0"
            type='number'
            className='text-2xl font-semibold'
            autoComplete="off"
            autoFocus
            value={enteredAmount}
            onChange={(e) => setEnteredAmount(e.target.value)}
          />
        </FormControl>
        <div className='flex flex-row items-center gap-4'>
          <FormControl className='flex-1'>
            <label>Tipo de cambio</label>
            <Input
              placeholder="00.0"
              type='number'
              className='text-2xl font-semibold'
              readOnly
              value={selectedCurrency ? selectedCurrency.rate : 0}
            />
          </FormControl>
          <FormControl className='flex-1'>
            <label>Moneda</label>
            <ComboCurrency selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} />
          </FormControl>
        </div>
        <FormControl>
          <label>Monto a entregar</label>
          <Input
            placeholder="00.0"
            type='number'
            className='text-2xl font-semibold'
            readOnly
            value={exchangeValue}
            onChange={(e) => setExchangeValue(e.target.value)}
          />
        </FormControl>
        <Button size='lg' onClick={handleSubmit} disabled={exchangeMutation.isPending}>Guardar</Button>
      </div>
    </div>
  )
}

export default ExchangeRate