import React, {useContext, useState} from 'react'
import {SiteContext} from '../../context/SiteContext';
import styles from './operations.module.css';
import {Button, FormControl, Input} from '../../components/atoms';
import {ComboBank, ComboClient} from '../../components/blocks';
import {useMutation} from '@tanstack/react-query';
import {Apis} from '../../utils/apis';
import axios from 'axios';
import {toast} from '../../hooks/useToast';


const CardPay = () => {

  const {fondo, config} = useContext(SiteContext);
  const [bank, setBank] = useState(null);
  const [cardType, setCardType] = useState('VISA');
  const [amount, setAmount] = useState('');
  const [cardNumber4, setCardNumber4] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handPayMutation = useMutation({
    mutationKey: ['op_handpay_redeem'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/operation-cardpay', data),
    onSuccess: () => {
      setSelectedCustomer(null)
      setAmount('')
      setCardType('VISA')
      setCardNumber4('')
      setBank(null)
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handlePayClick = () => {
    if (amount) {
      handPayMutation.mutate({
        amount: amount,
        cardNumber4: cardNumber4,
        cardType: cardType,
        customerId: config.genericModeCp ? selectedCustomer?.id : null,
        bankId: config.genericModeCp ? bank?.id : null,
        fondoId: fondo.id,
      });
    }
  };

  const getButtonStyle = (buttonCardType) => {
    return cardType === buttonCardType ? styles.selectedButton : styles.buttonGroup;
  };

  return (
    <>
      <div className='flex flex-row items-center justify-center gap-4 mt-2 mb-4'>
        <div className='h-[1px] bg-neutral-500 flex-1 flex'/>
        <span className='font-light text-neutral-500'>RETIRO POR TARJETA</span>
        <div className='h-[1px] bg-neutral-500 flex-1 flex'/>
      </div>
      <div className='flex flex-col self-stretch flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>
        {config.genericModeCp &&
          <>
            <FormControl>
              <label>Seleccione tipo de tarjeta</label>
              <div className='flex flex-row items-center self-stretch flex-1 gap-4'>
                <div className={getButtonStyle('VISA')} onClick={() => setCardType('VISA')}>
                  VISA
                </div>
                <div className={getButtonStyle('MASTERCARD')} onClick={() => setCardType('MASTERCARD')}>
                  MASTERCARD
                </div>
                <div className={getButtonStyle('DINERS')} onClick={() => setCardType('DINERS')}>
                  DINERS CLUB
                </div>
                <div className={getButtonStyle('AMEX')} onClick={() => setCardType('AMEX')}>
                  AMEX
                </div>
              </div>
            </FormControl>
            <div className='flex flex-row items-center gap-4'>
              <FormControl className='flex-1'>
                <label>Seleccione banco</label>
                <ComboBank selectedBank={bank} setSelectedBank={setBank} />
              </FormControl>
              <FormControl className='flex-1'>
                <label>Últimos 4 dígitos</label>
                <Input
                  placeholder="Opcional"
                  type='number'
                  className='text-2xl font-semibold'
                  autoComplete="off"
                  value={cardNumber4}
                  onChange={(e) => setCardNumber4(e.target.value)}
                />
              </FormControl>
            </div>
          </>
        }
        <FormControl className='flex-1'>
          <label>Ingrese monto</label>
          <Input
            placeholder="00.0"
            type='number'
            className='text-2xl font-semibold'
            autoComplete="off"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </FormControl>
        {config.genericModeCp &&
          <ComboClient setSelectedCustomer={setSelectedCustomer} selectedCustomer={selectedCustomer} />
        }
        <Button size='lg' onClick={handlePayClick}>Guardar</Button>
      </div>
    </>
  )
}

export default CardPay
