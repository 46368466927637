import React from 'react'
import {cn} from '../../../utils/general'

const FormControl = ({className,children}) => {
  return (
    <div className={cn("flex flex-col gap-2",className)}>
      {children}
    </div>
  )
}

export {FormControl}