import React from 'react'
import {Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogOverlay, DialogTitle} from '../../atoms'

const DialogConfirm = ({open, setOpen, onConfirm, body}) => {

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogOverlay />
      <DialogContent
        className='flex flex-col gap-4'
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>Confirmación</DialogTitle>
        </DialogHeader>
        {body}
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button onClick={handleConfirm}>
            Confirmar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DialogConfirm