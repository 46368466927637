import React, {useState} from 'react'
import styles from './historial.module.css';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../components/atoms';
import HisExpenses from './hisExpenses';
import HisExchangeRate from './hisExchangeRate';
import {BiReceipt} from 'react-icons/bi';
import {RiExchangeDollarLine} from 'react-icons/ri';

const HistorialOthers = () => {

  const [activeTab, setActiveTab] = useState('otros');
  
  return (
    <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className='flex flex-col flex-1'>
      <TabsContent value="otros">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>OTROS GASTOS</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>
      <TabsContent value="tipoCambio">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>TIPO DE CAMBIO</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>
      <div className='flex flex-col self-stretch flex-1 p-4 bg-neutral-800 rounded-2xl'>
        <TabsList>
          <TabsTrigger value="otros" asChild>
            <div className={styles.customTabsTrigger}>
              <BiReceipt size={24} />
              <span>Otros</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="tipoCambio" asChild>
            <div className={styles.customTabsTrigger}>
              <RiExchangeDollarLine size={24} />
              <span>Tipo cambio</span>
            </div>

          </TabsTrigger>
        </TabsList>
        <TabsContent value="otros" className='mt-6'>
          <HisExpenses />
        </TabsContent>
        <TabsContent value="tipoCambio" className='mt-6'>
          <HisExchangeRate />
        </TabsContent>
        </div>
    </Tabs>
  )
}

export default HistorialOthers
