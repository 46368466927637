import React, {useEffect} from "react";
import axios from "axios";
import {Navigate} from "react-router-dom";
import {useWauth} from "../../auth/WauthProvider";
import {Apis} from "../../utils/apis";
import {useQuery} from '@tanstack/react-query'

const CallbackPage = () => {

  const {handleAuthentication, isAuthenticated} = useWauth()
  const message = "Verificando cuenta"

  const referer = '/selector'

  const {isPending} = useQuery({
    queryKey: ['getUser'],
    queryFn: () => axios.get(Apis.USER + '/users/me?product=gaming')
      .then(res => {
        const userName = res.data.user.name;
        localStorage.setItem("name", JSON.stringify(userName));
        return res.data;
      })
  })

  useEffect(() => {
    handleAuthentication()
    // eslint-disable-next-line
  }, [])

  if (isAuthenticated) {
    return <Navigate to={referer}/>
  }

  if (isPending) return 'Redirigiendo...'

  return (
    <div>
      {message}
    </div>
  )
};

export default CallbackPage;
