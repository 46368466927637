import React, {useContext, useMemo} from 'react'
import {SiteContext} from '../../context/SiteContext';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {format, parseISO} from 'date-fns';
import {LuBanknote, LuListOrdered} from 'react-icons/lu';
import {EmptyState, ReactTable} from '../../components/blocks';
import {formatNumber} from '../../utils/general';
import {Button} from '../../components/atoms';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {TbGiftCard} from 'react-icons/tb';

const HisSaleTicket = () => {

  const {fondo, config,printer} = useContext(SiteContext);

  const {data, isFetched} = useQuery({
    queryKey: ['getTicketsSale', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/operation-ticket-venta/report/by-fondo?fondoId=' + fondo?.id)
      .then((res) => res.data),
    enabled: !!fondo?.id
  })

  const retryPrint = (id) => axios.post(`${Apis.CAJA}/operation-ticket-venta/${id}/retry-print`, {
    computerId: printer?.computerId
  });

  const retryModal = useConfirmDialog(
    '¿Deseas re imprimir este ticket?',
    'retry_print', //mutateKey
    'getTicketsSale', //volver a llamar para refrescar la data
    retryPrint // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'Fondo',
        accessorKey: 'fondoName'
      },
      {
        header: 'Promo',
        accessorKey: 'promo',
        cell: info => (
          <div className='flex flex-row justify-center'>
            {info.row.original.promo ?
              <TbGiftCard size={20}/>
              :
              '-'
            }
          </div>
        ),
      },
      {
        header: 'VSID',
        accessorKey: 'validationSystemId'
      },
      {
        header: 'Numero de ticket',
        accessorKey: 'validNumber'
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount !== null ? formatNumber(row.amount, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Estado',
        accessorKey: 'state',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },

      },
      {
        header: 'Creación',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Canje',
        id: 'redeemedAt',
        accessorFn: row => `${row.redeemedAt ? format(parseISO(row.redeemedAt), "dd/MM hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Canjeado en',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            {info.row.original.state === 'ERROR' ?
              <Button variant='outline' size='sm' onClick={() => retryModal.handleClick(info.row.original.id)}>Reimprimir</Button>
              :
              '--'
            }
          </div>
        ),
      },
    ]
    , [config,retryModal]
  )

  return (
    <div className='flex flex-col flex-1 gap-4'>
      {isFetched &&
        <>
          <div className='flex flex-row items-center gap-4'>
            <div className='flex flex-row items-center flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
              <div className='flex flex-col flex-1'>
                <span className='text-lg font-bold'>{data?.quantity ? formatNumber(data.quantity, null) : '--'}</span>
                <span className='text-xs font-light'>Tickets vendidos</span>
              </div>
              <div className='p-3 bg-neutral-900 rounded-xl'>
                <LuListOrdered size={20} />
              </div>
            </div>
            <div className='flex flex-row flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
              <div className='flex flex-col flex-1'>
                <span className='text-lg font-bold'>{data?.totalAmount ? formatNumber(data.totalAmount, config.symbol) : '--'}</span>
                <span className='text-xs font-light'>Total</span>
              </div>
              <div className='p-3 bg-neutral-900 rounded-xl'>
                <LuBanknote size={20} />
              </div>
            </div>
          </div>
          {data.data && data.data.length > 0 ?
            <ReactTable columns={columns} data={data.data} />
            :
            <EmptyState />
          }
        </>
      }
      <retryModal.ConfirmationModal />
    </div>
  )
}

export default HisSaleTicket
