import React, {forwardRef} from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import {cn} from "../../../utils/general";

const Tabs = TabsPrimitive.Root;

const TabsList = forwardRef(
  ({className, ...props}, ref) => (
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        "inline-flex items-center justify-center rounded-2xl gap-4",
        className
      )}
      {...props}
    />
  )
);
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = forwardRef(
  ({className, ...props}, ref) => (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-row items-center flex-1 border border-neutral-600 gap-1 cursor-pointer rounded-2xl transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50  data-[state=active]:bg-white data-[state=active]:text-neutral-950 data-[state=active]:border-white",
        className
      )}
      {...props}
    />
  )
);
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = forwardRef(
  ({className, ...props}, ref) => (
    <TabsPrimitive.Content
      ref={ref}
      className={cn(
        "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        className
      )}
      {...props}
    />
  )
);
TabsContent.displayName = TabsPrimitive.Content.displayName;

export {Tabs, TabsList, TabsTrigger, TabsContent};
