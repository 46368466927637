import React, {useContext} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import logo from '../../../assets/logo-wargos.png'
import {LuAlignJustify, LuAlignRight, LuArrowLeftRight, LuArrowRightLeft, LuBanknote, LuBinary, LuFileInput, LuHistory, LuLogOut, LuSearch} from 'react-icons/lu'
import styles from './sidebar.module.css';
import {cn} from '../../../utils/general';
import {Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '../../atoms';
import {SiteContext} from '../../../context/SiteContext';
import {TbMoneybag} from 'react-icons/tb';
import {HiOutlineSlotMachine} from '../../../assets/icons/slot-machine';
import {TbCreditCardPay} from "react-icons/tb";

const Sidebar = ({logout, open, setOpen}) => {

  const {sala, userInfo, fondo, setFondo} = useContext(SiteContext)
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const handleFondoSelection = () => {
    setFondo(null);
    localStorage.removeItem('fondo');
  };

  const menuItems = [
    {
      title: "Operaciones",
      path: "/",
      icon: <LuBinary size={16} />
    },
    {
      title: "Transferencias",
      path: "transfers",
      icon: <LuArrowLeftRight size={16} />
    },
    {
      title: "Billetero",
      path: "bill",
      icon: <LuBanknote size={16} />
    },
    {
      title: "Historial",
      path: "historial",
      icon: <LuHistory size={16} />
    },
    {
      title: "Consulta ticket",
      path: "ticket-review",
      icon: <LuSearch size={16} />
    },
    {
      title: "Tickets emitidos",
      path: "ticket-machine",
      icon: <HiOutlineSlotMachine size={16} />
    },
    {
      title: "Cashback",
      path: "cashback",
      icon: <TbCreditCardPay size={16} />
    },
    {
      title: "Cerrar turno",
      path: "close-turn",
      icon: <LuFileInput size={16} />
    }
  ]

  return (
    <div className={cn(styles.container, open ? styles["open"] : '')}>

      <div className={styles.headerContainer}>
        <div className={cn(styles.headerTitle, open ? styles["open"] : '')}>
          <div className={styles.containerLogo}>
            <img src={logo} alt='logo' />
          </div>
          <span className='text-[20px] font-semibold'>Wargos</span>
        </div>

        <div onClick={toggleSidebar} className={styles.headerToggle}>
          {open ? <LuAlignJustify size={24} /> : <LuAlignRight size={24} />}
        </div>
      </div>

      <div className='flex flex-col items-start self-stretch flex-1'>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {open ?
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <NavLink className={styles.menuItem} to={item.path}>
                      <div className={styles.menuItemIcon}>
                        {item.icon}
                      </div>
                      <span className={cn(styles.menuItemTitle, open ? styles["open"] : '')}>{item.title}</span>
                    </NavLink>
                  </TooltipTrigger>
                  <TooltipContent sideOffset={8} side="right">
                    <p>{item.title}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              :
              <NavLink className={styles.menuItem} to={item.path}>
                <div className={styles.menuItemIcon}>
                  {item.icon}
                </div>
                <span className={cn(styles.menuItemTitle, open ? styles["open"] : '')}>{item.title}</span>
              </NavLink>
            }
          </React.Fragment>
        ))}
      </div>
      <div className='flex flex-col gap-2 pt-4 border-t border-neutral-800'>
        {open ?
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <Button className='justify-center h-10 p-0' onClick={() => navigate('selector')}>
                  <LuArrowRightLeft size={16} />
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={8} side="right">
                <p>Cambiar sala</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <Button variant='outline' className='justify-center h-10 p-0 border-neutral-900' onClick={() => handleFondoSelection()}>
                  <TbMoneybag size={16} />
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={8} side="right">
                <p>{fondo?.name}</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <div
                  className='flex items-center justify-center w-10 h-10 font-bold cursor-pointer min-w-10 rounded-2xl hover:bg-white/10'
                  onClick={logout}
                >
                  <LuLogOut size={16} />
                </div>
              </TooltipTrigger>
              <TooltipContent sideOffset={8} side="right">
                <p>Salir</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          :
          <>
            <Button className='justify-start h-12 bg-amber-300' onClick={() => handleFondoSelection()}>
              <TbMoneybag size={16}/>
              <span className='flex-1 text-sm text-left capitalize'>
                {fondo?.name}
              </span>
              <LuArrowRightLeft size={16}/>

            </Button>
            <Button variant='outline' className='justify-start h-12 border-neutral-900' onClick={() => navigate('selector')}>
              <LuArrowRightLeft size={16} />
              <span className='flex-1 text-sm text-left capitalize'>
                {sala?.name}
              </span>
            </Button>
            <div className='flex flex-row items-center gap-2 p-2 rounded-2xl bg-neutral-900 hover:bg-neutral-800'>
              <div className={styles.avatar}>
                {`${userInfo?.user?.name.charAt(0)}${userInfo?.user?.lastName.charAt(0)}`}
              </div>
              <div className='flex flex-col flex-1 w-[116px]'>
                <span className='overflow-hidden text-sm uppercase text-ellipsis text-nowrap'>
                  {userInfo?.user?.name + ' ' + userInfo?.user?.lastName}
                </span>

              </div>
              <div
                className='flex items-center justify-center font-bold cursor-pointer w-9 h-9 min-w-9 rounded-2xl'
                onClick={logout}
              >
                <LuLogOut size={16} />
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default Sidebar
