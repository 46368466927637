import React from "react";
import {cn} from "../../../utils/general";

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  return (
    React.createElement("textarea", Object.assign({
      className: cn("flex min-h-[80px] w-full rounded-2xl border border-neutral-600 bg-transparent px-3 py-2 text-xl ring-offset-background placeholder:text-white/10 placeholder:font-light placeholder:text-xl focus-visible:outline-none focus-visible:border-neutral-400 disabled:cursor-not-allowed disabled:opacity-50", className),
      ref: ref
    }, props))
  );
});

Textarea.displayName = "Textarea";

export { Textarea };
