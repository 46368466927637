import React, {useState} from 'react'
import styles from './operations.module.css';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../components/atoms';
import {RiExchangeDollarLine} from 'react-icons/ri';
import {BiReceipt} from 'react-icons/bi';
import Expenses from './expenses';
import ExchangeRate from './exchangeRate';
import Refills from '../refills';


const OperationOthers = () => {

  const [activeTab, setActiveTab] = useState('gastos');

  return (
    <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className='flex flex-col flex-1'>
      <TabsContent value="gastos">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>OTROS GASTOS</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>
      <TabsContent value="tipoCambio">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>TIPO DE CAMBIO</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>
      <TabsContent value="refill">
        <div className='flex flex-row items-center justify-center gap-4 mb-4'>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
          <span className='font-light text-neutral-500'>RELLENOS</span>
          <div className='h-[1px] bg-neutral-500 flex-1 flex' />
        </div>
      </TabsContent>
      <div className='flex flex-col self-stretch flex-1 p-4 bg-neutral-800 rounded-2xl'>
        <TabsList>
          <TabsTrigger value="gastos" asChild>
            <div className={styles.customTabsTrigger}>
              <BiReceipt size={24} />
              <span>Gastos</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="tipoCambio" asChild>
            <div className={styles.customTabsTrigger}>
              <RiExchangeDollarLine size={24} />
              <span>Tipo cambio</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="refill" asChild>
            <div className={styles.customTabsTrigger}>
              <RiExchangeDollarLine size={24} />
              <span>Rellenos</span>
            </div>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="gastos">
          <Expenses />
        </TabsContent>
        <TabsContent value="tipoCambio">
          <ExchangeRate />
        </TabsContent>
        <TabsContent value="refill">
          <Refills />
        </TabsContent>
        </div>
    </Tabs>
  )
}

export default OperationOthers
